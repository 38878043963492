import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box, Flex, useTheme, VStack, Heading, Button, Spacer, Select, Checkbox,
  HStack, Tabs, TabList, Tab, TabPanels, TabPanel, Text,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import ReportCard from '../components/Cards/ReportCard';
import SectionFooter from '../components/NavMenu/SectionFooter';
import MomentDatePicker from '../components/ElementaryComponents/MomentDatePicker';
import { useFetchAnalyticsReportsQuery, useCreateAnalyticsReportMutation } from '../services/apis/reportsAPI';
import { sanitizeData } from '../utils/helpers';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';
import UserSelectModal from '../components/Modals/UserSelectModal';

const Analytics = ({
  variant = 'solid', customBtnStyle = {},
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { auth } = useContext(TEEAuthDataContext);
  const [reportType, setReportType] = useState('Aggregated');
  const [viewReportType, setViewReportType] = useState('All');
  const [showMyReports, setShowMyReports] = useState(false);
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const today = moment().format('MM/DD/YYYY');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const minDateForEndDate = startDate ? startDate.format('MM/DD/YYYY') : '01/01/2023';
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [optimisticReports, setOptimisticReports] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const indexedTabs = ['activity'];
  const reportSubType = indexedTabs[selectedTab];
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  const isAnalyticsPath = location.pathname === '/analytics';

  const handleTabChange = (index) => {
    setSelectedTab(index);
    setCurrentPage(1);
  };

  const [createReport, { isLoading, isError, error }] = useCreateAnalyticsReportMutation();

  const handleUserSelect = (userId, userName) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
  };

  const handleClearUser = () => {
    setSelectedUserId(null);
    setSelectedUserName(null);
  };

  const userSelect = reportType === 'Individual' && reportSubType === 'infraction';

  const handleCreateReport = async () => {
    let newReport = {
      author_id: sanitizeData(auth.user.profile.sub),
      start_date: startDate,
      end_date: endDate,
      report_type: reportType,
      report_sub_type: reportSubType,
    };
    if (userSelect) {
      newReport = {
        ...newReport,
        report_meta: {
          user_id: selectedUserId,
        },
      };
    }
    createReport(newReport);
  };

  const validateForm = useCallback(() => {
    const allDateInputFilled = startDate && endDate;

    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    const isStartDateInPast = momentStartDate.diff(moment(), 'minutes') < 0;
    const isEndDateAfterStartAndInPast = momentEndDate.diff(momentStartDate, 'minutes') > 0 && momentEndDate.diff(moment(), 'minutes') < 0;

    if (isStartDateInPast
        && isEndDateAfterStartAndInPast
        && allDateInputFilled
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    startDate,
    endDate,
  ]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const reportsQueryObj = {
    page_number: currentPage,
    page_size: selectedShowNumber,
    report_type: viewReportType !== 'All' ? viewReportType : undefined,
    report_sub_type: reportSubType,
    author_id: showMyReports ? loggedInUserId : undefined,
  };

  const {
    data: reportsData,
    isFetching: isFetchingReportsData,
    isError: isReportsDataError,
    error: reportsDataError,
  } = useFetchAnalyticsReportsQuery(reportsQueryObj);

  const analyticsTitleStyle = {
    ...theme.styles.global.h1,
    color: `${theme.colors.blue[800]}`,
    display: 'inline-block',
  };

  const canCreate = reportsData?.permissions?.report?.can_create;

  const handleOptimisticDelete = (reportId) => {
    const updatedReports = reportsData?.data?.filter((report) => report._id !== reportId);
    setOptimisticReports(updatedReports);
  };

  const handleFailedDelete = () => {
    setOptimisticReports(null);
  };

  const handleCheckboxChange = (checked) => {
    setShowMyReports(checked);
    setCurrentPage(1);
  };

  const handleSelectChange = (e) => {
    setViewReportType(e.target.value);
    setCurrentPage(1);
  };

  return (
    <Box w="full">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
      >
        {isAnalyticsPath ? (
          <Text style={analyticsTitleStyle}>Analytics</Text>
        ) : (
          <NavLink to="/training" style={analyticsTitleStyle}>Analytics</NavLink>
        )}
      </Flex>
      <Box>
        <Tabs onChange={handleTabChange} index={selectedTab}>
          <TabList>
            <Tab
              _selected={{
                fontWeight: 'bold',
                color: 'blue.600',
                borderBottom: '2px solid',
              }}
              style={{ width: '130px' }}
              position="relative"
            >
              Activity
            </Tab>

          </TabList>
          <VStack
            mt={2}
            style={{
              ...theme.styles.global.container, minHeight: '4rem', padding: '16px', alignSelf: 'stretch', alignItems: 'flex-start',
            }}
            spacing={4}
          >
            <Flex
              width="full"
              height="88px"
              justifyContent="space-between"
              alignItems="center"
              mr="4"
            >
              <Flex
                alignItems="center"
                mr={4}
              >
                <MomentDatePicker
                  label="From"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  maxDate={today}
                  style={{
                    '& input': {
                      height: '12px',
                      width: '105px',
                    },
                  }}
                />
                <MomentDatePicker
                  label="To"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={minDateForEndDate}
                  maxDate={today}
                  style={{
                    '& input': {
                      height: '12px',
                      width: '105px',
                    },
                  }}
                />
                <Select
                  aria-label="Select create report type"
                  onChange={(e) => setReportType(e.target.value)}
                  height="46px"
                  width="220px"
                  mt={7}
                >
                  <>
                    <option value="Aggregated" aria-label="Aggregate Activity">Aggregate Activity</option>
                    <option value="Individual" aria-label="Individual Activity">Individual Activity</option>
                  </>
                </Select>
              </Flex>
              { userSelect && !selectedUserId && (
              <UserSelectModal
                onUserSelect={handleUserSelect}
              />
              )}
              { userSelect && selectedUserId && (
                <Flex align="center">
                  <Text style={{ ...theme.styles.global.h4 }} minWidth="90px" mt={7} mr={4}>
                    {selectedUserName}
                  </Text>
                  <Button
                    onClick={handleClearUser}
                    height="46px"
                    width="120px"
                    colorScheme="blue"
                    variant="outline"
                    mt={7}
                    mr={4}
                    style={{
                      ...theme.styles.global.button,
                      fontWeight: '600',
                      backgroundColor: 'white',
                    }}
                    size="md"
                  >
                    Clear
                  </Button>
                </Flex>
              )}
              <Spacer />
              {
              canCreate && (
              <Button
                colorScheme="blue"
                bg="blue.500"
                variant={variant}
                style={{ ...theme.styles.global.button, fontWeight: '600', ...customBtnStyle }}
                size="lg"
                minWidth="200px"
                height="56px"
                onClick={handleCreateReport}
                isDisabled={!isFormValid || (userSelect && !selectedUserId)}
              >
                {reportType === 'Aggregated' ? 'Create Aggregate Report' : 'Create Individual Report'}
              </Button>
              )
            }
            </Flex>
            {
            isLoading && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="loading" alert_title="Creating Report..." />
            </Box>
            )
          }
            {
            isError && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={error} />
              </Box>
            )
          }
          </VStack>
          <Heading
            as="h2"
            size="lg"
            mt={8}
            color="blue.800"
            style={{
              ...theme.styles.global.h3,
            }}
          >
            Reports
          </Heading>
          <Flex
            mt={2}
            style={{
              ...theme.styles.global.container, minHeight: '4rem', padding: '16px', alignSelf: 'stretch', alignItems: 'flex-start',
            }}
            spacing={4}
            direction="column"
          >
            <HStack
              width="full"
              borderBottom="1px solid #CED8DD"
              alignItems="center"
              pb={4}
            >
              <Select
                aria-label="Select generated report type"
                onChange={handleSelectChange}
                value={viewReportType}
                height="46px"
                width="220px"
                mr={4}
              >
                <option value="All" aria-label="All">All</option>
                <option value="Aggregated" aria-label="Aggregate">Aggregate</option>
                <option value="Individual" aria-label="Individual">Individual</option>
              </Select>
              <Checkbox
                size="lg"
                label="test"
                isChecked={showMyReports}
                onChange={(e) => handleCheckboxChange(e.target.checked)}
                style={{
                  ...theme.styles.global.body,
                }}
              >
                Show Only Reports I Created
              </Checkbox>
            </HStack>
            <TabPanels>
              { isReportsDataError && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={reportsDataError} />
              </Box>
              )}
              { isFetchingReportsData && (
                <Box style={{ padding: '1rem 1.5rem' }}>
                  <StatusAlert status="loading" alert_title="Fetching Reports..." />
                </Box>
              )}
              <TabPanel>
                {
  (optimisticReports || (reportsData?.data ?? [])).map((report) => (
    <ReportCard
      key={report._id}
      report={{
        ...report,
        start_date: moment(report.start_date).format('MM/DD/YY'),
        end_date: moment(report.end_date).format('MM/DD/YY'),
        created_at: moment(report.created_at).format('h:mma MM/DD/YY'),
      }}
      onDeleteOptimistic={handleOptimisticDelete}
      onFailedDelete={handleFailedDelete}
    />
  ))
}
              </TabPanel>
            </TabPanels>
            <SectionFooter
              selectedShowNumber={selectedShowNumber}
              setSelectedShowNumber={setSelectedShowNumber}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalCount={reportsData?.meta.total_pages}
            />
          </Flex>
        </Tabs>

      </Box>
    </Box>
  );
};

export default Analytics;
