import React, { useContext } from 'react';
import {
  DefaultLayout,
  ExternalAppsContext,
  fetchUserApps,
  Footer,
  NotificationBar,
  TopBar,
  TopBarLeft,
  TopBarRight,
  useAuthActions,
  useNotificationsBar,
  UserProfileContext,
} from '@texas-education-exchange/edx-portal-shared';
import { Flex } from '@chakra-ui/react';
import RootLayout from './RootLayout';

const DefaultLayoutWrapper = ({
  auth,
  edxAppConfig,
  notificationBarMessage,
  notificationId,
  isClosingSession,
  onLogin,
  onLogout,
}) => {
  const { userProfile } = useContext(UserProfileContext);
  const { externalApps } = useContext(ExternalAppsContext);
  const { handleChangeTenantId } = useAuthActions();

  const { showNotificationsBar, onCloseNotificationsBar } = useNotificationsBar({ show: true });

  const handleTestClick = async () => {
    if (auth.user && userProfile && edxAppConfig) {
      const appsList = await fetchUserApps(
        auth.user.access_token,
        userProfile?.tenantId,
        edxAppConfig.api.baseUri,
      );

      console.log('test list', appsList);
    }
  };

  const moreOptions = [
    { name: 'Account Info', url: null },
    { name: 'Online Community', url: null },
    { name: 'Help', url: null },
  ];

  return (
    userProfile && (
    <DefaultLayout
      topBar={(
        <TopBar
          leftComponent={(
            <TopBarLeft
              appName={edxAppConfig.app.subtitle}
              list={externalApps}
              menuOptions={moreOptions}
            />
                    )}
          rightComponent={(
            <TopBarRight
              profileData={userProfile}
              isClosingSession={isClosingSession}
              onLogin={onLogin}
              onLogout={onLogout}
              onChangeTenantId={handleChangeTenantId}
            />
                    )}
        />
            )}
      notificationBar={(
        <Flex onClick={handleTestClick} w="full" id="NotificationBar">
          <NotificationBar
            show={showNotificationsBar}
            onClose={onCloseNotificationsBar}
            message={notificationBarMessage}
            notificationId={notificationId}
          />
        </Flex>
            )}
      content={(
        <RootLayout />
      )}
      footer={<Footer />}
    />
    )
  );
};

export default DefaultLayoutWrapper;
