/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useParams, useNavigate, NavLink, useLocation,
} from 'react-router-dom';
import {
  useTheme, Button, Heading, Table, Thead, Tbody, Tr, Th, Td, Flex, HStack, Box, Tooltip,
} from '@chakra-ui/react';
import Papa from 'papaparse';
import DownloadButton from '../ElementaryComponents/DownloadButton';
import SimplePagination from '../ElementaryComponents/SimplePagination';
import { useDownloadAnalyticsReportQuery } from '../../services/apis/reportsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import DeleteModal from '../Modals/DeleteModal';

const ReportDetails = () => {
  const { report_id } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const authorId = location.state?.author_id;
  const reportType = location.state?.type;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const reports = useSelector((state) => state.reports);
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const report = reports.find((r) => r.id === parseInt(report_id, 10));
  const {
    data: presignedUrlData, isFetching: isReportFetching,
    isError: isReportError, error: reportError,
  } = useDownloadAnalyticsReportQuery(report_id);

  useEffect(() => {
    const presignedUrl = presignedUrlData?.presigned_url;
    if (presignedUrl) {
      fetch(presignedUrl)
        .then((response) => response.text())
        .then((csvContent) => {
          const parsedData = Papa.parse(csvContent, {
            header: true,
            dynamicTyping: true,
          }).data;
          setData(parsedData);
        })
        .catch((error) => console.error('Error fetching presignedUrl:', error));
    }
  }, [presignedUrlData]);

  if (isReportFetching) return <StatusAlert status="loading" alert_title="Fetching Reports Data..." />;
  if (isReportError) return <StatusAlert status="error" error={reportError} />;

  const displayedData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage,
  );

  const totalRows = (data.length - 1);

  const longCellStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const handleBack = () => {
    if (reportType === 'infraction') {
      navigate('/moderation-report');
    } else {
      navigate('/analytics');
    }
  };

  return (
    <Box mt={4}>
      <NavLink
        to={reportType === 'infraction' ? '/moderation-report' : '/analytics'}
      >
        <Heading
          as="h1"
          color="blue.800"
          style={{
            ...theme.styles.global.h1,
            display: 'inline-block',
          }}
        >
          {reportType === 'infraction' ? 'Moderation' : 'Analytics'}
        </Heading>
      </NavLink>
      <HStack
        justifyContent="space-between"
        mb={2}
        mt={4}
      >
        <Heading
          as="h2"
          color="blue.800"
          style={{
            ...theme.styles.global.h3,
          }}
        >
          Your
          {' '}
          {report?.type}
          {' '}
          Activity Report
        </Heading>
        <Button
          size="lg"
          onClick={handleBack}
          variant="noOutline"
          colorScheme="blue"
          color="blue.500"
          sx={{ padding: 0 }}
        >
          Back to Your Reports
        </Button>
      </HStack>
      <Flex
        style={{
          ...theme.styles.global.container,
          borderRadius: '8px',
        }}
        flexDirection="column"
        width="full"
        p="40px"
      >
        <Table
          style={{
            ...theme.styles.global.container,
            tableLayout: 'fixed',
            width: '100%',
            borderRadius: '2px',
            boxShadow: 'none',
            fontFamily: 'Archivo Narrow',
          }}
          mb="40px"
        >
          <Thead>
            <Tr>
              {data.length > 0 && Object.keys(data[0]).map((key) => (
                <Th
                  key={key}
                  style={{
                    ...theme.styles.global.body,
                    fontFamily: 'Archivo Narrow',
                    width: key === 'Content' ? '500px' : undefined,
                  }}
                >
                  {key}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {displayedData.map((row, rowIndex) => (
              <Tr
                key={rowIndex}
                style={{
                  ...theme.styles.global.body,
                  fontFamily: 'Archivo Narrow',
                  fontWeight: '300',
                }}
              >
                {Object.entries(row).map(([key, value]) => (
                  <Td key={key}>
                    <Tooltip label={value}>
                      <div
                        style={{
                          maxWidth: key === 'Content' ? '500px' : 'auto',
                          ...(
                            ['Email', 'Content', 'Content URL', 'Taken By'].includes(key)
                              ? longCellStyles
                              : {}
                          ),
                        }}
                      >
                        {value}
                      </div>
                    </Tooltip>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
        <SimplePagination
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
        />
        <HStack
          justifyContent="flex-end"
          mt={6}
          gap={4}
        >
          {
              loggedInUserId === authorId && (
              <DeleteModal
                deleteType="report"
                itemId={report_id}
                variant="solid"
                customBtnStyle={{ width: '108px' }}
                size="md"
              />
              )
            }
          <DownloadButton
            report_id={report_id}
            fileName={report?.file_object?.file_name}
            variant="outline"
            buttonLabel="Download Report"
            size="md"
          />
        </HStack>
      </Flex>
    </Box>
  );
};

export default ReportDetails;
